<template>
  <div>
    <button type="button" class="cashapp-info" @click="toggle">CashApp</button>
    <div class="cashapp" :class="show ? 'cashapp-show' : ''">
      <a class="link" target="_blank" href="https://cash.app/help/us/en-us/3101-buying-bitcoin">
        If you do not have Bitcoin in your CashApp account, here is how to buy within CashApp: CLICK HERE
      </a>
      <br/>
      <em>You can also send bitcoin using lightning or the blockchain:</em> <br/>
      1. Tap the Money tab on your Cash App home screen <br/>
      2. Tap the Bitcoin tile <br/>
      3. Tap the Airplane button <br/>
      4. Choose Send Bitcoin <br/>
      5. Enter the amount and the recipient’s BTC address <br/>
      6. Tap Next <br/>
      7. Select a speed <br/>
      8. Tap Confirm & Send <br/>
      <em class="link" @click="buy">When paying with CashApp you still need to pay via bitcoin - PURCHASE HERE.</em>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCashAppInfo',
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    buy () {
      this.$emit('buy')
    }
  }
}
</script>

<style lang="scss">
.cashapp-info {
  background: #fff;
  color: $primary;
  border: solid 1px $primary !important;
  font-size: 1.15em;
  border-radius: 4px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  max-width: 750px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: none;
  vertical-align: top;
  cursor: pointer;
  overflow: hidden;
}

.cashapp {
  display: none;
  background: #fff;
  border-radius: 8px;
  padding: 1em;
  border: solid 1px $primary !important;
}

.cashapp-show {
  display: block;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
