<template>
  <div>
    <button type="button" class="paypal-info" @click="toggle">PayPal</button>
    <div class="paypal" :class="show ? 'paypal-show' : ''">
      <a class="link" target="_blank"
         href="https://www.paypal.com/us/digital-wallet/manage-money/crypto?utm_medium=hpmod&utm_source=paypal&utm_campaign=crypto">
        If you do not have Bitcoin in your PayPal account, here is how to buy within PayPal: CLICK HERE
      </a>
      <br/>
      <em>PayPal supports the native transfer of cryptocurrencies between PayPal and other wallets and exchanges. Here
        is how to transfer to an external BTC address or PayPal customer:</em> <br/>

      1. Go to the <b>Finances tab.</b> <br/>
      2. Tap your crypto balance. <br/>
      3. Tap the ⇄ Transfer arrows. <br/>
      4. Tap <b>Send.</b> <br/>
      - If you want to sync your PayPal contacts so that you can send them crypto, tap Sync Your Contacts. <br/>
      - Tap OK to allow PayPal to access your contacts. <br/>
      5. Select the contact you want to send crypto to. <br/>
      6. You’ll need to enter an external crypto address if you want to transfer cryptocurrency out of your account.
      <br/>
      7. Copy and paste an address, manually enter it, or scan a QR code by tapping the QR code button to the right of
      the search box. <br/>
      8. Enter the amount you want to send and tap <b>Next.</b> <br/>
      - Be sure to send it to the right recipient. Crypto sent to the wrong address will be lost. <br/>
      - You can put an exact amount of a cryptocurrency to send if you know it <br/>
      - Or you can enter a dollar amount. We’ll calculate approximately how much crypto is worth the amount you entered
      and send that amount of crypto. The dollar value of your sent crypto will change over time, so it could be worth
      more or less by the time the recipient gets it. <br/>
      9. Review and tap <b>Send Now.</b> <br/>
      <em class="link" @click="buy">When paying with PayPal you still need to pay via bitcoin - PURCHASE HERE.</em>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPayPalInfo',
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    buy () {
      this.$emit('buy')
    }
  }
}
</script>

<style lang="scss">
.paypal-info {
  background: #fff;
  color: $primary;
  border: solid 1px $primary !important;
  font-size: 1.15em;
  border-radius: 4px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  max-width: 750px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: none;
  vertical-align: top;
  cursor: pointer;
  overflow: hidden;
}

.paypal {
  display: none;
  background: #fff;
  border-radius: 8px;
  padding: 1em;
  border: solid 1px $primary !important;
}

.paypal-show {
  display: block;
}
</style>
