<template>
  <l-default>
    <OTitle>Checkout</OTitle>
    <OCRow class="u-mb-3">
      <div>{{ email }}</div>
      <div class="u-ml-auto">{{ credits }} credits</div>
    </OCRow>

    <CVerifyEmail v-if="!isActive"></CVerifyEmail>

    <div v-if="isActive">
      <CBuyButton :credits="Number(parameters.credits)" :bonus_credits="Number(parameters.bonus_credits)"
                  :cost="Number(parameters.amount)"
                  :always="isAlways" :isLink="false" class="c-grid__item"></CBuyButton>

      <div style="text-align:center; margin-bottom: 0.5em">
        <em>ALL PURCHASE ARE NON-REFUNDABLE, FOR MORE DETAILS SEE OUR </em>
        <o-link :to="{name: 'refund'}" style="text-decoration: underline">Refund Policy</o-link>
      </div>

      <CCoingate ref="coingate" :index="1" :credits="Number(parameters.credits)+Number(parameters.bonus_credits)"
                 :cost="Number(parameters.amount)" :label="coingateLabel"
                 :email="email" class="c-grid__item"></CCoingate>

      <CBitcoinCryptoWallet style="margin-top: 1.5em" @buy="buy"/>

    </div>

  </l-default>
</template>

<script>
import { mapGetters } from 'vuex'
import LDefault from '@/layouts/LDefault'
import OTitle from '../objects/OTitle'
import CBuyButton from '../components/CBuyButton'
import OCRow from '../objects/containers/OCRow'
import CCoingate from '../components/CCoingate'
import OLink from '../objects/OLink'
import CBitcoinCryptoWallet from '../components/CBitcoinCryptoWallet.vue'
import CVerifyEmail from '@/components/CVerifyEmail.vue'

export default {
  name: 'PageCheckout',
  components: {
    CBitcoinCryptoWallet,
    CCoingate,
    CBuyButton,
    LDefault,
    OTitle,
    OCRow,
    OLink,
    CVerifyEmail
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      credits: 'getCredits',
      email: 'getEmail',
      isActive: 'isActive',
      phones: 'getPhones'
    }),
    parameters: function () {
      return this.$route.query
    },
    isAlways: function () {
      return true
    },
    coingateLabel: function () {
      return 'BITCOIN/ALTCOIN'
    }
  },
  methods: {
    buy () {
      this.$refs.coingate.submit(new Event('submit', undefined))
    }
  }
}
</script>

<style lang="scss">
.c-grid {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 1rem;
}

.c-grid__title {
  margin: 0;
  padding: 0.25em 0;
  text-transform: uppercase;
}

.c-grid__item {
  margin: 0 0 0.5rem;
}

.u-ml-auto {
  margin-left: auto;
}

.u-mb-3 {
  margin-bottom: 1.5rem;
}

</style>
