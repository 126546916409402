<template>
  <div>
    <em>YOU CAN SEND BITCOIN WITH ANY CRYPTO WALLET INCLUDING:</em>
    <CCashAppInfo @buy="buy" style="margin-bottom: 0.5em; margin-top: 0.5em;" />
    <CPayPalInfo @buy="buy" style="margin-bottom: 0.5em;" />
    <CVenmoInfo @buy="buy" />
  </div>
</template>

<script>
import CCashAppInfo from '../components/CCashAppInfo.vue'
import CPayPalInfo from '../components/CPayPalInfo.vue'
import CVenmoInfo from '../components/CVenmoInfo.vue'

export default {
  name: 'CBitcoinCryptoWallet',
  components: { CCashAppInfo, CPayPalInfo, CVenmoInfo },
  methods: {
    buy () {
      this.$emit('buy')
    }
  }
}
</script>
